<template>
    <b-modal id="print-modal" title="Cetak Disposal Asset" no-close-on-backdrop no-close-on-esc>
        <b-form-group label="Tanggal Awal">
            <b-form-input type="date" v-model="form.tgl_awal"></b-form-input>
        </b-form-group>
        <b-form-group label="Tanggal Akhir">
            <b-form-input type="date" v-model="form.tgl_akhir"></b-form-input>
        </b-form-group>
        <br>
        <template #modal-footer>
            <b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">Cetak</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BFormInput, BButton} from 'bootstrap-vue'
export default {
    components: {
        BModal, BFormGroup, BFormInput, BButton
    },
    data: () => ({
        form: {
            tgl_awal: null,
            tgl_akhir: null
        }
    }),
    computed: {
        isValidForm() {
            if(!this.form.tgl_awal || !this.form.tgl_akhir) {
                return false
            }

            return true
        }
    },
    methods: {
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap isi tanggal awal dan tanggal akhir'
                })
                return false
            }

            this.$emit('submit', this.form)
        }
    }
}
</script>